import axios from "axios";
import { useRouter } from "vue-router";
const router = useRouter();
import { ElMessage } from "element-plus";
const qs = require("qs");

let path = location.protocol + process.env.VUE_APP_BASE_API;

// axios基本配置
// axios.defaults.timeout = 50000
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
axios.defaults.withCredentials = true;
// http request 拦截器
axios.interceptors.request.use(
  (config) => {
    // console.log(config)
    // var userInfo = JSON.parse(localStorage.getItem('userInfo'))
    // config.headers['token'] = userInfo ? userInfo.token : '';
    // config.headers['userId'] = userInfo ? userInfo.id : '';
    return config;
  },
  (err) => {
    // console.log("err",err);
    return Promise.reject(err);
  }
);

// http response 拦截器
axios.interceptors.response.use(
  (response) => {
    errcode(response.data.code);
    return response.data;
  },
  (error) => {
    if (error && error.response) {
      console.log("error:" + error.response.status);
      responseError(error);
    }
    return Promise.reject(error);
  }
);

//错误执行提示
const responseError = function (err) {
  let newMessage = new Date().getTime();
  let oldMessage = "";
  switch (err.response.status) {
    case 400:
      oldMessage = JSON.parse(sessionStorage.getItem("time"));
      if (oldMessage + 500 < newMessage) {
        ElMessage.error(err.response.data.message);
      }
      break;
    case 401:
      oldMessage = JSON.parse(sessionStorage.getItem("time"));
      if (oldMessage + 500 < newMessage) {
        let url = window.location.href,
          href = path + "/alpha/sso/redirect?redirectUri=" + encodeURI(url);
        window.location.href = href;
      }
      break;
    case 403:
      ElMessage.error("403:没有权限");
      break;
    case 404:
      break;
    case 408:
      break;
    case 500:
      ElMessage.error(err.response.data.message);
      break;
    case 501:
      err.message = "501:服务未实现";
      break;
    case 502:
      err.message = "502:网关错误";
      break;
    case 503:
      err.message = "503:服务不可用";
      break;
    case 504:
      err.message = "504:网关超时";
      break;
    case 505:
      err.message = "505:HTTP版本不受支持";
      break;
    default:
  }
  sessionStorage.setItem("time", JSON.stringify(newMessage));
};

const http = {
  /**
   * post 请求方法
   * @param url
   * @param data
   * @returns {Promise}
   */
  post(url, data) {
    url = path + url;
    return new Promise((resolve, reject) => {
      axios.post(url, qs.stringify(data)).then(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  /**
   * get 请求方法
   * @param url
   * @param data
   * @returns {Promise}
   */
  get(url, data) {
    url = path + url;
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          params: data,
        })
        .then(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  },
  /**
   * post 请求方法  请求类型为application/json
   * @param url
   * @param data
   * @returns {Promise}
   */
  json_post(url, data) {
    url = path + url;
    return new Promise((resolve, reject) => {
      axios
        .post(url, data, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        })
        .then(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  },
  files(url, params) {
    url = path + url;
    var data = new FormData();
    for (var key in params) {
      if (params[key]) {
        data.append(key, params[key]);
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .post(url, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
    });
  },
};
function errcode(code) {
  if (code == 2000) {
    ElMessage.error("登录过期,请重新登录!");
    router.push({ path: "/" });
  }
}

export default http;
