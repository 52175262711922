<template>
  <div class="header">
    <span @click="jumpHome"> WIKI </span>
  </div>
  <div class="main">
    <router-view :loginName="loginName" />
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import * as api from "../lib/home.js";
const router = useRouter();
let loginName = ref("");

onMounted(() => {
  getUserInfo();
});

const getUserInfo = () => {
  api.userInfo().then((res) => {
    if (res.result == 200) {
      loginName.value = res.loginName;
    }
  });
};

const jumpHome = () => {
  router.replace("/homeContent");
};
</script>

<style lang="scss" scoped>
.header {
  height: 50px;
  line-height: 50px;
  padding: 0 10px;
  border-bottom: 1px solid #e8e8e8;
  font-weight: 500;
  box-sizing: border-box;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);

  span {
    cursor: pointer;
  }
}
.main {
  min-height: calc(100vh - 60px);
}
</style>
