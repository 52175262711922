<template>
  <div class="app">
    <router-view />
  </div>
</template>

<style lang="scss">
html,
body,
div,
ul,
b,
li,
i,
em,
h1,
h2,
h3,
span,
a {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
}

html,
body {
  width: 100%;
  height: 100%;
}
.el-button.is-disabled,
.el-button.is-disabled:active,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
  color: #00000040 !important;
}
</style>
